import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Card from "../components/Card";
import { Row } from '../components/layout/grid'
import { Button } from '../components/layout/form'

import { capitalize, formatData } from "../utils/tools";
import { loadProduct, addToFavoriteList, removeFromFavoriteList, } from "../utils/actions/product";

const api = 'store.aalladine.com'

/**
 * Category component show a list of products belong to the current category
 */
export default class Category extends React.Component {

  state = {
    likedProducts: [],// list of liked products to display a like image red or not
    products: [],// list of products used for first render and when user load more products via Ajax
    loading: false,// loading state or not
    page: 11, // this state needed when send ajax request to load more products @see woocommerce doc
    therIsMoreToLoad: true,// check if there is more products to load when load a new products and the number of those products=10
  }

  /**
   * this called when user add a product to favorite list
   * and add a slug of this product to state
   */
  likeProduct = ({slug, image, price, regular_price, name, acf, average_rating, rating_count}) => {
    let product;
    if( !this.state.likedProducts.includes(slug) ) {
      product = {
        slug,
        name: name,
        image: image.src,
        price, regular_price,
        average_rating,
        rating_count,
        acf
      };
      this.setState(prevState => ({
        likedProducts: [
          ...prevState.likedProducts,
          product.slug
        ]
      }));
      addToFavoriteList(product);
    } else {
      let index = this.state.likedProducts.indexOf(slug);
      this.setState(prevState => ({
        likedProducts: [
          ...prevState.likedProducts.slice(0, index),
          ...prevState.likedProducts.slice(index+1),
        ]
      }));
      removeFromFavoriteList(slug);
    }

  }

  /**
   * check if the product is liked by customer
   * @param {String} slug the slug of product
   * @return {Boolean} true if is liked otherwise false
   */
   checkIfIsLiked = slug => this.state.likedProducts.includes(slug)

  /**
   * @name addProducts
   * add new products List to state
   * @param {Array} products new set of products
   */
   addProducts = newProducts => this.setState(prevState => ({
     products: [...prevState.products, ...newProducts]
   }))

  /**
   *
   */
   onClick = async e => {
     console.log("click loading");
     e.preventDefault();
     this.setState({loading: true});
     try {
       let oldProducts = await loadProduct(`https://${api}/wp-json/wc/v3/products?page=${this.state.page}`);
       this.setState(prevState => ({
         products: [...prevState.products, ...oldProducts ],
         page: prevState.page+1,
         loading: false,
         therIsMoreToLoad: oldProducts.length < 10? false : true
       }));
     } catch (e) {
       this.setState({loading: false});
       alert('Verifiez Votre connexion internet/données ou ressayez s.v.p');
     }
   }

  componentDidMount(){
    let { allWcProducts: products, } = this.props.data;
    products = products? products.edges.map((product) => product) : [];
    this.addProducts(products);
  }

  render() {
    const {
      wcProductsCategories: category,
      allWcProductsCategories: categories
    } = this.props.data;
    let { products, loading, therIsMoreToLoad, } = this.state;
    let productsList = formatData(products.length? products : [], 4);
    let categoriesKeys = categories.edges.map(({ node: category }) => category.name);

    return (
      <Layout
        navigate={this.props.navigate}
        categories={categories}
      >
        <SEO 
          title={`${capitalize(category.name)}`} 
          keywords={[`aalladine`, `ecommerce boutique`, ...categoriesKeys]} />


        <div className="category-page">

          <h1 className='container__main-title capitalize'>
            <strong className="container__main__name  align-middle">{capitalize(category.name)}</strong>&nbsp;
            <span className={'container__main__count align-middle'}>{category.count} produits</span>
            <p className="container__main__description mb0">{category.description}</p>
          </h1>


          {
            productsList.length && productsList.map( row => (
              <div className="row cards__block" key={row}>
                {
                  row.map(({ node: product }) => (
                    <Card
                      key={product.slug}
                      isLiked={this.checkIfIsLiked(product.slug)}
                      data={product}
                      actionEvent={[this.likeProduct]}
                      enableAction
                     />
                  ))
                }
              </div>
            ))
          }

          <Row className="page-new-products__load-button-container center">
            {
              therIsMoreToLoad && products.length?
                (
                  <Button
                    type='button'
                    onClick={this.onClick}
                    className="page-new-products__load-button"
                    disabled={loading}
                  > 
                      <span className="page-new-products__button-text">
                        {
                          !loading ?
                          ('Charger plus de produits') :
                          (
                            <>
                              Charegement <span className="dots-anime"></span>
                            </>
                          )
                        }
                      </span>
                  </Button>
                ):
                (<></>)
            }
          </Row>

        </div>
      </Layout>
    );
  }
}

export const pageQuery = graphql`
  query ( $id: String!, $slug: String! ) {
    allWcProductsCategories (
      filter : { slug : {nin: ["uncategorized", "non-classe"]} },
      sort: { fields: [count], order: DESC }
     ) {
      edges {
       node {
         id
         name
         slug
         display
         _links{
           self{
             href
           }
         }
         count
       }
      }
    }
    allWcProducts( filter: { categories: { elemMatch: { slug: { eq: $slug } } } } )  {
      edges {
       node {
         id
         slug
         name
         price
         regular_price
         rating_count
         average_rating
         acf {
           language
         }
         images {
           id
           name
           alt
           src
         }
       }
      }
    }
    wcProductsCategories( id: { eq: $id } ){
     id
     name
     slug
     count
     description
   }
 }
`
